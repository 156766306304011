var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-bottom": 5,
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-auto text-capitalize",
                    attrs: {
                      disabled: _vm.disabled,
                      rounded: "",
                      text: "",
                      color: "main"
                    }
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-filter-outline")
                  ]),
                  _vm._v(" Filters "),
                  _c("v-badge", {
                    staticClass: "ml-2 mb-2",
                    attrs: {
                      color: "accent",
                      content: _vm.filterCount,
                      value: _vm.filterCount > 0
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.filterMenu,
        callback: function($$v) {
          _vm.filterMenu = $$v
        },
        expression: "filterMenu"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { width: "350" } },
        [
          _c(
            "v-card-text",
            _vm._l(_vm.searchFields, function(field, idx) {
              return _c(
                "v-row",
                { key: idx, attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: field.type === "boolean" ? 6 : null }
                    },
                    [
                      field.type === "text"
                        ? _c("v-text-field", {
                            staticStyle: { padding: "5px" },
                            attrs: {
                              label: field.name,
                              outlined: "",
                              "hide-details": "auto",
                              hint: "Press enter to search",
                              clearable: "",
                              loading:
                                Boolean(_vm.filters[field.key]) && _vm.loading,
                              color: "primary",
                              dense: "",
                              "prepend-inner-icon": "mdi-magnify",
                              "background-color": "white"
                            },
                            on: {
                              "click:clear": function($event) {
                                return _vm.clearFilter(field.key)
                              },
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.updateSearchFilters($event)
                              }
                            },
                            model: {
                              value: _vm.filters["" + field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "" + field.key, $$v)
                              },
                              expression: "filters[`${field.key}`]"
                            }
                          })
                        : field.type === "autocomplete"
                        ? _c("v-autocomplete", {
                            staticStyle: { padding: "5px" },
                            attrs: {
                              label: field.name,
                              outlined: "",
                              "hide-details": "",
                              clearable: "",
                              items: field.options,
                              "item-text": "name",
                              "item-value": "id",
                              "item-color": "action",
                              "background-color": "white",
                              dense: "",
                              "prepend-inner-icon": "mdi-filter-outline"
                            },
                            on: { change: _vm.updateSearchFilters },
                            model: {
                              value: _vm.filters["" + field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "" + field.key, $$v)
                              },
                              expression: "filters[`${field.key}`]"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }